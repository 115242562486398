body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login-card {
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: rgb(222, 49, 99);
  }
  
  .login-form .form-group {
    margin-bottom: 20px;
  }
  
  .login-form .form-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .login-form .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .password-wrapper {
    display: flex;
    align-items: center;
  }
  
  .password-wrapper .toggle-password {
    margin-left: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .submit-btn {
    width: 100%;
    background-color: rgb(222, 49, 99);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: rgb(199, 23, 73);
  }
  
  .login-links {
    margin-top: 20px;
    text-align: left;
  }
  
  .link-text {
    color: #111;
    font-size: 14px;
    cursor: pointer;
  }
  
  .link-text:hover {
    text-decoration: underline;
  }
  