  body {
      margin: 0;
      font-family: "Arial", sans-serif;
    }
    
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      color: #21cb98;
      position: fixed;
      top: 0;
      width: 100%;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      background-color: white;
    }
    
    .logo {
      font-size: 1.9rem;
      font-weight: bold;
    }
    
    .nav-links {
      display: flex;
      gap: 20px;
      align-items: center;
      transition: transform 0.3s ease-in-out;
    }
    
    .nav-links a {
      text-decoration: none;
      color: #28363d;
      font-size: 1.02rem;
      transition: color 0.3s;
      font-weight: 500;
      font-family: "Inter";
    }
    
    .nav-links a:hover {
      color: #004d3f;
    }
    
    .login-btn {
      padding: 8px 16px;
      color: white;
      background-color: #DE3163;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.3s;
    }
    
    .login-btn:hover {
      background-color: #d9f5ec;
    }
    
    .hamburger {
      display: none;
      cursor: pointer;
      z-index: 1100;
    }
    
    .menu-icon,
    .close-icon {
      font-size: 1.5rem;
      color: #111;
    }
    
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }
    
    .nav-links.open {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 250px;
      background-color: #DE3163;
      padding: 20px;
      gap: 15px;
      box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
      transform: translateX(0);
      transition: transform 0.3s ease-in-out;
    }
    
    .nav-links.open a,
    .nav-links.open .login-btn {
      margin-top: 10px;
      color: white;
    }

    .nav-links.open {
      transform: translateX(0); /* Slide in smoothly */
    }
    
    .dropdown-menu {
      position: absolute;
      z-index: 1000;
      background-color: #fff;
      border: 1px solid #ddd;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
    
    .dropdown-item {
      padding: 10px;
      color: #111;
      text-decoration: none;
      display: block;
    }
    
    .dropdown-item:hover {
      background-color: #f4f4f4;
    }
    
    @media (max-width: 768px) {
      .nav-links {
        display: none;
        transition: transform 0.3s ease-in-out;
      }
    
      .hamburger {
        display: block;
      }
    
      .nav-links.open {
        display: flex;

        transition: transform 0.3s ease-in-out;
      }

      .login-btn {
          background-color: #fff;
          
      }

      .nav-links.open .login-btn {
        margin-top: 10px;
        color: #111;
      }
      

    }
    