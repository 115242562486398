.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.card-text {
  color: #555;
  font-size: 0.9rem;
}

.cars-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.car-card {
  background-color: #fff;
  border-radius: 15px;
  width: 300px;
  border: 1px solid rgb(211, 210, 210);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.car-card:hover {

  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
}

.card-header {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.dots-icon {
  font-size: 24px;
  color: #7f8c8d;
}

.car-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.car-info {
  padding: 20px;
  text-align: left;
}

.car-name {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 8px;
}

.car-details {
  font-size: 14px;
  color: #95a5a6;
  margin-bottom: 12px;
}

.car-details span {
  font-weight: 500;
}

.price {
  font-size: 22px;
  font-weight: 600;
  color: #111;
}

.dropdown-menu {
  position: absolute;
  top: 40px;
  right: 0;
  left: -60px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 100;
  padding: 10px 0;
  min-width: 120px;
  display: flex;
  flex-direction: column;
}

.dropdown-menu button {
  all: unset;
  padding: 10px 15px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  text-align: left;
}

.dropdown-menu button:hover {
  background-color: #f5f5f5;
  color: #e74c3c;
}

@media only screen and (max-width: 600px) {
  .mt-10{
    margin-top: 10px;
  }
}