body {
    font-family: 'Poppins', sans-serif;
  }
  
  .car-card-2 {
    background-color: #f9f9f9; /* Light background for a clean look */
    border-radius: 12px;
    overflow: hidden;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 15px;
    border: 1px solid gray;
    width: 300px;
    height: auto;
  }
  
  .car-card:hover {
    /* transform: translateY(-6px); */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .car-image-2 {
    width: 100%;
    height: 170px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .car-card:hover .car-image {
    /* transform: scale(1.03); */
  }
  
  .car-details {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 200px;
  }
  
  .car-details h5 {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
    color: #094c6d;
  }
  
  .car-details p {
    margin: 0;
    color: #666;
    font-size: 0.95rem;
  }
  
  .car-details .price {
    color: #111; /* More prominent price color */
    font-weight: 500;
    font-size: 1.2rem;
  }
  
  .car-details .location {
    color: #5f5353;
    font-size: 0.9rem;
  }
  
  .car-details .variant {
    font-weight: 500;
    color: #757575;
    font-size: 1rem;
  }
  
  .car-details p:not(.price):not(.location):not(.variant) {
    font-size: 0.9rem;
    color: #555;
  }
  
  
  
  @media (max-width: 768px) {
    .car-card-2{
      width: 100%;
      /* height: 50%; */
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    
    .car-image-2{
      width: 250px;
      height: 100px;
    }
    .car-details h5{
      font-size: 0.9rem;
    }
    .car-details .variant{
      font-size: 0.9rem;
    }
    .car-details .price{
      font-size: 1.1rem;
    }
    .car-details{
      line-height: 20px;
    }
  }