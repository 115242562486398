.how-it-works-container {
  background: #f5f5f5;
  padding: 50px 20px;
  text-align: center;
}

.how-it-works-title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 40px;
}

.how-it-works-steps {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  padding: 20px 0;
  gap: 20px;
  scroll-snap-type: x mandatory;
}

.step {
  background-color: white;
  width: 250px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex-shrink: 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  scroll-snap-align: start;
}

.step:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.step-icon {
  background-color: #DE3163;
  width: 60px;
  height: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 50%;
  font-size: 30px;
}

.step h3 {
  font-size: 24px;
  color: #333;
  margin: 10px 0;
}

.step p {
  font-size: 16px;
  color: #777;
}

.how-it-works-steps::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .how-it-works-steps {
    flex-direction: column;
    align-items: center;
  }

  .step {
    margin-bottom: 30px;
    width: 80%;
  }
}
