.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }
  
  .empty-icon {
    font-size: 5rem;
    color: #d3d3d3;
    margin-bottom: 20px;
  }
  
  .empty-title {
    font-size: 1.8rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .empty-description {
    font-size: 1rem;
    color: #777;
    max-width: 400px;
  }
  