.footer {
    background-color: #e5e7e9;
    color: #111;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    min-width: 220px;
    margin-right: 20px;
  }
  
  .footer-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .footer-subtitle {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .footer-links {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-links li {
    margin: 5px 0;
  }
  
  .footer-links a {
    color: #111;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .social-icons {
    margin-top: 10px;
  }
  
  .social-icons a {
    color: #DE3163;
    margin-right: 10px;
    font-size: 20px;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #DE3163;
  }
  
  .newsletter-form {
    display: flex;
  }
  
  .newsletter-form input {
    padding: 8px;
    border: none;
    border-radius: 4px;
    margin-right: 5px;
  }
  
  .newsletter-form button {
    padding: 8px 12px;
    background-color: #f0e68c;
    color: #1a9d74;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .newsletter-form button:hover {
    background-color: #e5d053;
  }
  
  .footer-bottom {
    text-align: center;
    padding: 20px 0;
    background-color: #fff;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: left;
    }
  
    .footer-section {
      margin-right: 0;
      margin-bottom: 20px;
      text-align: left;
    }
  
    .newsletter-form {
      flex-direction: column;
      align-items: left;
    }
  
    .newsletter-form input {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  