.edit-page p{
    top: 10px;
    position: relative;
}

.edit-page div{
    margin-top: 15px;
}

.edit-page .profile-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .profile-image {
    position: relative;
    width: 150px;
    height: 150px;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ccc;
  }
  
  .camera-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    background: white;
    border-radius: 50%;
    padding: 5px;
  }
  
  .popup-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 300px;
  }
  
  .links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .delete-account {
    color: red;
  }
  