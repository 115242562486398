.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #DE3163;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s;
}

.back-to-top:hover {
    background-color: #DE3163;
}