.blogs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    min-height: 100vh;
  }
  
  .blog-card {
    background-color: white;
    border-radius: 12px;
    padding: 20px 40px;
    color: #333;
    font-family: 'Roboto', sans-serif;
  }
  
  .blog-title {
    font-size: 34px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
    font-family: 'Inter';
  }
  
  .blog-date {
    font-size: 16px;
    color: #7a7a7a;
    margin-bottom: 25px;
  }
  
  .blog-image {
    width: 100%;
    height: 450px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 30px;
  }
  
  .blog-content {
    font-size: 18px;
    line-height: 1.7;
    color: #555;
  }
  
  .blog-subtitle {
    font-size: 24px;
    font-weight: 600;
    color: #2a2a2a;
    /* margin-top: 30px; */
    font-family: 'Inter';
  }
  
  @media only screen and (max-width: 600px) {
   .blogs-container{
    margin-top: 80px;
    padding: 5px;
   }
  }