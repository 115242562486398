.favourite-screen {
    padding: 20px;
    text-align: center;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #1a9d74;
  }
  
  .car-list {
    overflow-x: auto;
  }
  
  .car-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .car-table th, .car-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .car-table th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .car-image {
    /* width: 100px;
    height: auto; */
    object-fit: cover;
    border-radius: 5px;
  }
  
  .remove-button {
    background-color: #ff4d4d;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .remove-button:hover {
    background-color: #e60000;
  }
  
  .no-cars {
    font-size: 18px;
    color: #555;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .car-table th, .car-table td {
      padding: 10px;
    }
  
    .car-image {
      width: 80px;
    }
  
    .remove-button {
      padding: 8px 12px;
    }
  }
  