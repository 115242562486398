.popular-brands-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px;
    background-color: #f9f9f9;
    border-radius: 12px;
    text-align: center;
  }
  
  .title {
    font-size: 2.5rem;
    color: #DE3163;
    margin-bottom: 40px;
  }
  
  .brands-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .brand-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .brand-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .brand-logo {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  