@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
}

body{
  cursor: pointer;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}


section {
  padding: 60px;
}

.breadcrumb{
  font-size: 18px;
  font-family: 'Inter';
  font-weight: 400;
  color: #111;
  background: transparent;
}

.primary-medium {
  background-color: #DE3163;
  padding: 5px;
  color: #fff;
  font-family: 'Inter';
  border: none;
  outline: none;
  border-radius: 8px;
  margin-bottom: 10px;
}

.font-primary{
  color: #DE3163;
}

.icon-sm{
  font-size: 10px;
}

@media (max-width: 768px) {
  section {
    padding: 0;
  }
  .carousel-inner img{
    height: 100px;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(218, 214, 215);
  border-radius: 10px;
  border: 3px solid #fff;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

scrollbar {
  width: 12px;
}

scrollbar-thumb {
  background-color: rgb(222, 49, 99);
  border-radius: 10px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

button.primary {
  background-color: #DE3163;
  color: #fff;
  padding: 8px;
  border: none;
  outline: none;
  border-radius: 8px;
}

.font-medium {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 27px;
}

.cusstom-input,
select {
  width: 100%;
  padding: 9px;
  outline: none;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
}


.overlay.show {
  visibility: visible;
}

.overlay .message {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.medium-paragraph {
  font-family: 'Inter';
  line-height: 30px;
}

.space-around {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

button.secondary {
  background-color: #EB6600;
  color: #fff;
}

button.secondary-btn {
  border: 1px solid rgb(231, 197, 197);
}

a {
  text-decoration: none;
  color: #111;
}

a:hover {
  text-decoration: none;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: rgb(222, 49, 99);
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(197, 149, 161);
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: rgb(222, 49, 99);
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: rgb(222, 49, 99);
  border-radius: 50%;
  cursor: pointer;
}
