.filter-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    max-height: 80vh;
    overflow-y: auto; /* Enable scroll when content overflows */
    transition: all 0.3s ease;
    margin: 10px;
  }
  
  .filter-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter-header h3 {
    font-size: 26px;
    color: #21cb98;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .filter-item {
    margin-bottom: 20px;
  }
  
  .filter-item label {
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
    font-weight: 500;
    font-family: 'Inter';
  }
  
  .price-slider {
    width: 100%;
    margin-top: 5px;
  }
  
  .price-range {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #333;
    margin-top: 8px;
  }
  
  .search-box {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  .accordion-button {
    width: 100%;
    padding: 12px;
    background-color: #f4f4f4;
    border: none;
    font-size: 16px;
    text-align: left;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }
  
  .accordion-button:hover {
    background-color: #e6e6e6;
  }
  
  .accordion-button.active {
    background-color: #cb2169;
    color: #fff;
  }
  
  .accordion-content {
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .checkbox-label input {
    margin-right: 10px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
  }
  
  .radio-label {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .checkbox-group {
    display: flex;
    flex-direction: column;
  }
  
  .apply-filter {
    width: 100%;
    padding: 12px;
    background-color: #21cb98;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .apply-filter:hover {
    background-color: #1c9a7f;
  }
  
  .price-range-container {
    display: flex;
    justify-content: space-between;
  }
  
  .price-slider {
    width: 48%;
    margin-right: 10px;
  }
  
  .price-range {
    margin-top: 10px;
    font-weight: bold;
  }
  