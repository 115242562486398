.news-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .news-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .news-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
  }
  
  .news-description {
    font-size: 1.2rem;
    color: #555;
    margin-top: 10px;
  }
  
  .news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .news-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #21cb98;
    padding: 6px;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
    
  }
  
  .news-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .news-content {
    padding: 20px;
  }
  
  .news-card-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #222;
    margin-bottom: 10px;
  }
  
  .news-category {
    font-size: 0.9rem;
    font-weight: 500;
    color: #666;
    margin-bottom: 10px;
  }
  
  .news-text {
    font-size: 1rem;
    color: #444;
    margin-bottom: 20px;
  }
  
  .news-link {
    font-size: 1rem;
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
  }
  
  .news-link:hover {
    text-decoration: underline;
  }
  